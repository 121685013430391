<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <b-card :title="plan.name">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-card-text>
                <p>流量: <b>{{ plan.traffic }}G</b></p>
                <p>价格: -月<code>{{ plan.month_price }}</code>
                  -半年<code>{{ plan.six_month_price }}</code>
                  -年<code>{{ plan.year_price }}</code></p>
                <p>{{ plan.info }}</p>
              </b-card-text>
            </b-col>
            <!-- occupation -->
            <b-col cols="12">
              <b-form-group
                label="周期"
                label-for="h-occupation"
              >
                <b-form-select
                  v-model="month"
                  :options="monthOpts"
                />
              </b-form-group>

            </b-col>

            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="order"
              >
                订购
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col
      v-if="$can('read', 'Analytics')"
      cols="12"
      md="6"
    >
      <b-card title="购买须知">
        <b-card-text class="text-danger">
          <strong>注意!</strong> 原则上不退款，初次购买建议月付。
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { NoEnoughBalance } from '@/http/code'
import { getMonthInfo } from '@/utils/utils'
import useHttp from '@/http/useHttp'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BForm,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BForm,
    BButton,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      monthOpts: [],
      id: 1,
      month: 1,
      plan: {},
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getData()
  },
  methods: {
    getData() {
      this.monthOpts = this.getMonthInfo()
      useHttp.getShadowxPlanInfo(this.id).then(res => {
        this.plan = res.data.data
      }).catch(error => {
        this.showToast('danger', '出错了', error)
      })
    },
    order() {
      // console.log(this.month)
      useHttp.shadowsNewOrder({
        plan_id: this.id,
        month: this.month,
      }).then(res => {
        const order = res.data.data
        this.$router.push(`/shadowx/orders/${order.id}`)
      }).catch(err => {
        // console.log(err)
        //  错误处理，根据status code
        let msg = ''
        // console.log(err.response.data.error)
        // console.log('error code', err.response.data.error.code)
        switch (err.response.data.error.code) { // @todo  err.response.data.error.code
          case NoEnoughBalance:
            msg = '余额不足，请充值。'
            break
          default:
            msg = '未知错误'
            // console.log('unexcept code', err)
        }
        this.showToast('danger', '出错了', msg)
      })
    },
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    getMonthInfo,
  },
}
</script>

<style>

</style>
